@font-face {
    font-family: 'gobold';
    src: url('./goboldB.otf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'gobold';
    src: url('./gobold.otf');
    font-weight: 300;
    font-style: normal;
}

.card-container {
	margin: auto;
	font-family: gobold;
	color: white;
	text-align: center;
	text-shadow: 0 0 8px  #e2eff0;
	z-index: 20;
	width: 100%;
}

.card-follow {
	margin: 30px 0 20px 0;
	display: inline-flex;
	align-items: center;
	text-overflow: none;
	width: 20%;
}

.shop-button {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

@media screen and (max-width: 1024px) and (min-width: 580px) {
  	.card-follow {
		width: 50%;
	}
}

@media screen and (max-width: 580px) {
  	.card-follow {
		width: 60%;
	}
}

.cut {
	background-color: white;
	height: 3px;
	width: 100%;
	box-shadow: 0 0 8px  #e2eff0;
}

.text-follow {
	padding: 0 10px;
	white-space: nowrap;
}

.card-icon {
	filter: drop-shadow(0 0 8px  #e2eff0);
	display: flex;
	justify-content: center;
	align-items: center;
}

.livre-icon {
	margin-top: 25px;
	filter: drop-shadow(0 0 8px  #e2eff0);
}

.livre-icon:hover {
	filter: drop-shadow(0 0 13px  #ffffff);
}